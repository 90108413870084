import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
export default function projects() {
    return (
        <Layout>
        <SEO
          title="Projects"
          keywords={[`versionprime`, `full stack`, `react`, `angular`]}
        />
        <section className="mx-auto">
          
        </section>
      </Layout>
        );
}